import { Avatar, Hidden, Icon, IconButton, MenuItem, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import AddBoxIcon from '@material-ui/icons/AddBox'
import clsx from 'clsx'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DisasterFormDialog from '../../../../app/components/disaster/DisasterFormDialog'
import DocumentCreate from '../../../../app/components/documentsList/DocumentForms/Admin/DocumentCreate/DocumentCreate'
import AskingDocumentFormDialog from '../../../../app/components/documentsList/DocumentForms/User/AskingDocumentsFormDialog'
import SubmitDocumentFormDialog from '../../../../app/components/documentsList/DocumentForms/User/SubmitDocumentsFormDialog'
import IconHelper from '../../../../app/helpers/IconHelper'
import RoutesHelper from '../../../../app/helpers/RoutesHelper'
import UserHelper from '../../../../app/helpers/UserHelper'
import { useAuth, useAuthUser } from '../../../../app/hooks/useAuth'
import { useCustomer } from '../../../../app/hooks/useCustomer'
import { useCustomers, useCustomersLoadedExisting } from '../../../../app/hooks/useCustomers'
import useNavContext from '../../../../app/hooks/useNavContext'
import useSettings from '../../../../app/hooks/useSettings'
import useSnackBar from '../../../../app/hooks/useSnackBar'
import { CustomerGeneric_customer } from '../../../../graphql/queries/typings/CustomerGeneric'
import { EnumUser } from '../../../../graphql/typings/global_types'
import { MatxMenu } from '../../../../matx'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    topbar: {
        top: 0,
        zIndex: 96,
        transition: 'all 0.3s ease',
        background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0))',

        '& .topbar-hold': {
            backgroundColor: palette.primary.main,
            height: 80,
            paddingLeft: 18,
            // paddingRight: 20,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        '& .fixed': {
            boxShadow: theme.shadows[8],
            height: 64,
        },
    },
    userMenu: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 24,
        padding: 4,
        '& span': {
            margin: '0 8px',
            // color: palette.text.secondary
        },
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 185,
    },
}))

const Layout1Topbar = () => {
    const theme = useTheme()
    const classes = useStyles()
    const { settings, updateSettings } = useSettings()
    const auth = useAuth()
    const { customers, customersLoading } = useCustomers()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    const fixed = settings?.layout1Settings?.topbar?.fixed
    const isAuthenticated = !auth.loading && auth.user

    const updateSidebarMode = (sidebarSettings: any) => {
        updateSettings({
            layout1Settings: {
                leftSidebar: {
                    ...sidebarSettings,
                },
            },
        })
    }

    const handleSidebarToggle = () => {
        let { layout1Settings } = settings
        let mode

        if (isMdScreen) {
            mode = layout1Settings.leftSidebar.mode === 'close' ? 'mobile' : 'close'
        } else {
            mode = layout1Settings.leftSidebar.mode === 'full' ? 'close' : 'full'
        }

        updateSidebarMode({ mode })
    }

    let topLeftComponent = null

    if (!customersLoading && customers && !auth.loading && auth.user && auth.user.userType === EnumUser.admin) {
        topLeftComponent = <TopBarAEACustomerSelect />
    } else if (!auth.loading && auth.user && auth.user.userType === EnumUser.user) {
        topLeftComponent = <TopBarUserCustomer />
    }

    return (
        <div className={classes.topbar}>
            <div className={clsx({ 'topbar-hold': true, fixed: fixed })}>
                <div className="flex justify-between items-center h-full">
                    <div className="flex" style={{ alignItems: 'center', overflow: 'hidden' }}>
                        <IconButton onClick={handleSidebarToggle} className="hide-on-pc">
                            <Icon>menu</Icon>
                        </IconButton>
                        {topLeftComponent}
                    </div>
                    <div className="flex items-center">{isAuthenticated && <TopBarIconsConnected />}</div>
                </div>
            </div>
        </div>
    )
}

const TopBarUserCustomer = () => {
    const { customer, customerLoading } = useCustomer()

    return customerLoading || !customer ? <>Bll</> : <Typography variant="h6">{customer.title}</Typography>
}

const TopBarAEACustomerSelect = () => {
    const { customers } = useCustomersLoadedExisting()
    const { customer } = useCustomer()
    const navigate = useNavigate()

    const menuItems = [...customers]
        .sort((customerA, customerB) => {
            return customerA.title.localeCompare(customerB.title)
        })
        .map((customer) => {
            return (
                <MenuItem key={customer.id} value={customer.id}>
                    {customer.title}
                </MenuItem>
            )
        })

    const onCustomerChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const customerId = event.target.value

        navigate(RoutesHelper.views.AEA.customer.Home.to({ customerId }))
    }

    return (
        <TextField
            placeholder="Clients"
            value={customer ? customer.id : 0}
            variant="outlined"
            size="small"
            select
            onChange={onCustomerChange}>
            <MenuItem key={0} value={0} disabled>
                Clients
            </MenuItem>
            {menuItems}
        </TextField>
    )
}

const TopBarIconsConnected = () => {
    const { user, logout } = useAuthUser()
    const { openSnack } = useSnackBar()
    const { customer } = useCustomer()

    const [docFormOpen, setDocFormOpen] = useState<boolean>(false)
    // Is used by user and by admin // Because they don't use it at a same time ^_^
    const [addDocDialogOpen, setAddDocDialogOpen] = useState<boolean>(false)
    const [disasterFormOpen, setDisasterFormOpen] = useState<boolean>(false)

    const classes = useStyles()

    const userFullName = UserHelper.getFullName(user.firstname, user.lastname)
    const userAvatar = UserHelper.getAvatar(user)
    const isAdmin = user.userType === EnumUser.admin

    /****************************
     * User pre-creating disaster
     ***************************/

    const onUserCreateDisasterClick = () => {
        setDisasterFormOpen(true)
    }

    const onUserDisasterFormDone = () => {
        openSnack({
            message: 'Sinistre déclaré auprès des experts',
            type: 'success',
        })
    }

    /****************************
     * User asking for a document
     ***************************/

    const onUserAskDocumentClick = () => {
        setDocFormOpen(true)
    }

    const onUserDocumentFormDone = () => {
        openSnack({
            message: 'Document demandé avec succès !',
            type: 'success',
        })
    }
    /****************************
     * User submitting a document
     ***************************/

    const onUserSubmitDocumentClick = () => {
        setAddDocDialogOpen(true)
    }

    const onUserSubmitDocumentDone = () => {
        openSnack({
            message: 'Document(s) envoyé(s) avec succès ! Une notification a été envoyée aux experts pour validation.',
            type: 'success',
        })
    }

    /****************************
     * Admin submitting a document
     ***************************/

    const onAdminAddClick = () => {
        setAddDocDialogOpen(true)
    }

    const onAdminAddDocDone = () => {
        openSnack({
            message: 'Documents ajoutés avec succès !',
            type: 'success',
        })
    }

    return (
        <Fragment>
            {!isAdmin && customer && (
                <Fragment>
                    <MatxMenu
                        menuButton={
                            <IconButton>
                                <AddBoxIcon fontSize="large" color="secondary" />
                            </IconButton>
                        }>
                        <>
                            <MenuItem onClick={onUserCreateDisasterClick} className={classes.menuItem}>
                                <Icon>{IconHelper.elementIcons.disasterIcon}</Icon>
                                <span className="pl-4"> Déclarer un sinistre </span>
                            </MenuItem>
                            <MenuItem onClick={onUserAskDocumentClick} className={classes.menuItem}>
                                <Icon>{IconHelper.elementIcons.docsIcon}</Icon>
                                <span className="pl-4"> Demander un document </span>
                            </MenuItem>
                            <MenuItem onClick={onUserSubmitDocumentClick} className={classes.menuItem}>
                                <Icon>backup</Icon>
                                <span className="pl-4"> Soumettre des documents </span>
                            </MenuItem>
                        </>
                    </MatxMenu>
                    <AskingDocumentFormDialog
                        open={docFormOpen}
                        onDone={onUserDocumentFormDone}
                        closeDialog={() => setDocFormOpen(false)}
                    />
                    <SubmitDocumentFormDialog
                        open={addDocDialogOpen}
                        onDone={onUserSubmitDocumentDone}
                        closeDialog={() => setAddDocDialogOpen(false)}
                    />
                    <DisasterFormDialog
                        open={disasterFormOpen}
                        onDone={onUserDisasterFormDone}
                        closeDialog={() => setDisasterFormOpen(false)}
                    />
                </Fragment>
            )}

            {isAdmin && customer && (
                <Fragment>
                    <MatxMenu
                        menuButton={
                            <IconButton>
                                <AddBoxIcon fontSize="large" color="secondary" />
                            </IconButton>
                        }>
                        <>
                            <MenuItem onClick={onAdminAddClick} className={classes.menuItem}>
                                <Icon>{IconHelper.elementIcons.docsIcon}</Icon>
                                <span className="pl-4"> Déposer des documents </span>
                            </MenuItem>
                        </>
                    </MatxMenu>

                    <DocumentCreate
                        open={addDocDialogOpen}
                        onDone={onAdminAddDocDone}
                        closeDialog={() => setAddDocDialogOpen(false)}
                    />
                </Fragment>
            )}

            {/* <NotificationProvider>
                <NotificationBar />
            </NotificationProvider> */}

            {/* <NotificationBar2 /> */}

            <MatxMenu
                menuButton={
                    <div className={classes.userMenu}>
                        <Hidden xsDown>
                            <span>
                                <strong>{userFullName}</strong>
                            </span>
                        </Hidden>
                        <Avatar className="cursor-pointer" src={userAvatar} />
                    </div>
                }
                menuButtonClassName="mr-2">
                <>
                    {/* <MenuItem>
                        <Link className={classes.menuItem} to="/">
                            <Icon> home </Icon>
                            <span className="pl-4"> Home </span>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link className={classes.menuItem} to="/page-layouts/user-profile">
                            <Icon> person </Icon>
                            <span className="pl-4"> Profile </span>
                        </Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItem}>
                        <Icon> settings </Icon>
                        <span className="pl-4"> Settings </span>
                    </MenuItem> */}
                    {customer && <AdminContractLink customer={customer} />}
                    <MenuItem onClick={logout} className={classes.menuItem}>
                        <Icon> power_settings_new </Icon>
                        <span className="pl-4"> Déconnexion </span>
                    </MenuItem>
                </>
            </MatxMenu>
        </Fragment>
    )
}

type AdminContractLinkProps = {
    customer: Pick<CustomerGeneric_customer, 'id'>
}

// We use the customer prop to ensure that it has been loaded
// Because customer is used by useNavContext
const AdminContractLink = ({ customer }: AdminContractLinkProps) => {
    const classes = useStyles()
    const auth = useAuthUser()

    const isAdmin = auth.user.userType === EnumUser.admin

    const navigate = useNavigate()
    const { getAdminContractRoute } = useNavContext()

    const goToAdminContract = () => {
        navigate(getAdminContractRoute())
    }

    const contractText = isAdmin ? 'Contrat client' : 'Mon contrat avec AEA'

    return (
        <MenuItem onClick={goToAdminContract} className={classes.menuItem}>
            <Icon> {IconHelper.elementIcons.docsIcon} </Icon>
            <span className="pl-4"> {contractText} </span>
        </MenuItem>
    )
}

export default React.memo(Layout1Topbar)
